<template>
  <v-container fluid>
    <v-col
      cols="12"
      v-if="loading">
      <div class="text-center" >
        <v-progress-circular
          indeterminate
          color="primary" />
      </div>
    </v-col>
    <form
      @submit.prevent="save"
      v-else>
      <v-stepper
        v-model="e1"
        non-linear
        class="mb-2">
        <v-stepper-header>
          <v-stepper-step
            @click="save(0)"
            editable
            step="1">
            Neu
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step
            @click="save(10)"
            editable
            step="2">
            Erneut anrufen
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step
            @click="save(30)"
            step="3"
            editable>
            Erfolgreich
          </v-stepper-step>
        </v-stepper-header>
      </v-stepper>

      <NoteModal
        type="1"
        @success="loadRecall"
        :foreignId="recall.id">
        <v-icon>phone</v-icon>
        Telefonprotokoll anlegen
      </NoteModal>

      <v-tabs
        class="mt-2"
        v-model="active"
        slider-color="primary">
        <v-tab
          v-for="tab in tabs"
          :key="tab"
          ripple>
          <template v-if="tab !== 'Notizen'">{{ tab }}</template>
          <template v-else>
            <v-badge
              color="grey lighten-1"
              right>
              <template slot="badge">
                <span>{{ recall.notes.length }}</span>
              </template>
              <span>{{ tab }}</span>
            </v-badge>
          </template>
        </v-tab>

        <v-tab-item key="Allgmein">
          <v-row>
            <v-col cols="4">
              <v-card class="margin-card">
                <v-card-text>
                  <h3>
                    <v-icon class="icon-heading">person</v-icon>
                    Kunde
                  </h3>
                  <div class="details">{{ recall.name }}</div>
                  <div class="details">{{ recall.phone }}</div>
                  <div class="details">{{ time }}</div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-card>
                <v-card-text>
                  <h3 class="mt-0">Produkt</h3>
                  {{ productName }}
                  <h3>Kommentar</h3>
                  {{ recall.comment }}
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item key="Notizen">
          <NoteTab
            type="1"
            :foreignId="recall.id"
            :items="recall.notes"
            @success="loadRecall"
          />
        </v-tab-item>
      </v-tabs>
    </form>
  </v-container>
</template>

<script>
import AppointmentsAPI from '@/api/appointments'
import NoteModal from '@/components/NoteModal.vue'
import NoteTab from '@/components/NoteTab.vue'

export default {
  data() {
    return {
      active: null,
      statusTypes: [
        { key: 0, value: 'Neu' },
        { key: 10, value: 'Erneut anrufen' },
        { key: 30, value: 'Erfolgreich' },
      ],
      loading: false,
      recall: null,
      tabs: ['Allgemein', 'Notizen'],
    }
  },
  created() {
    this.loadRecall()
  },
  computed: {
    time() {
      return this.$options.filters.daytime(this.recall.time)
    },
    productName() {
      if (this.recall && this.recall.product) {
        return this.recall.product.fullname
      }
      return 'Kein Produkt ausgewählt'
    },
  },
  methods: {
    loadRecall() {
      this.loading = true
      AppointmentsAPI.getRecall(this.$route.params.id).then((response) => {
        this.recall = response
        this.$store.commit('app/setTitle', `Rückrufwunsch vom ${this._f('datetime')(this.recall.created_at)}`)
        this.loading = false
      })
    },
    save(status) {
      AppointmentsAPI.updateRecall(this.recall.id, status).catch(() => {
        this.$toasted.error('Der Status des Rückrufs konnte nicht geändert werden')
      })
    },
  },
  components: {
    NoteModal,
    NoteTab,
  },
}
</script>

<style lang="scss" scoped>
    form {
        width: 100%;
    }

    .margin-card {
        margin-top: 5px;
        height: 100%;

        .icon-heading {
            font-size: 30px;
        }

        .details {
            font-size: 18px;
        }

        h3 {
            margin: 10px 0;
        }
    }
</style>
